import React, { useState, useEffect } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from './assets/global/Theme-variable';
import Themeroutes from "./routes/ThemeRoutes";
import Cookies from "js-cookie";
import Services from "./Services/Services";
import { jwtDecode } from "jwt-decode";


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const routing = useRoutes(Themeroutes);
  const secureFlag = { secure: true }; 
  const theme = baseTheme;

  useEffect(() => {
    const validateAccessToken = async () => {
      const cookiesTokenValue = Cookies.get("access_token");
  
      if (cookiesTokenValue) {
        const decodeToken = jwtDecode(cookiesTokenValue);
        const token_expireTime = decodeToken.exp;
        const current_time = Math.floor(Date.now() / 1000);
  
        if (decodeToken) {
          if (current_time >= token_expireTime) {
  
            const refreshToken = Cookies.get("refresh_token");  
            const bodyjson = {
              refresh_token: refreshToken,
            };
            try {
              const response =
                await Services.IVPValidateAccessTokengenByRefreshToken(
                  bodyjson
                );
              if (response.status === 200) {
                const newAccessToken = response.data.data.access_token;
                Cookies.set("access_token", newAccessToken, {
                  expires: 1,
                  ...secureFlag,
                });
  
                const newRefreshToken = response.data.data.refresh_token;
                Cookies.set("refresh_token", newRefreshToken, {
                  expires: 1,
                  ...secureFlag,
                });
  
              }
            } catch (error) {
              }
            }
        }
      }
    };
    validateAccessToken();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {  routing}
    </ThemeProvider>
  );

};

export default App;


