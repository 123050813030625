import React from "react";
import { lazy } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
const InstructorLayout = lazy(() => import("../layouts/FullLayout/InstructorLayout.js"));
const CisoLayout = lazy(() => import("../layouts/FullLayout/CisoLayout.js"));
const AdminLayout = lazy(() => import("../layouts/FullLayout/AdminLayout.js"));
const ReviewerLayout = lazy(() => import("../layouts/FullLayout/ReviewerLayout.js"));
const InstructorDashboardLayout = lazy(() => import("../layouts/FullLayout/InstructorDashboardLayout.js"));
const AwarenessLayout = lazy(() => import("../layouts/FullLayout/AwarenessLayout.js"));
const CoordinatorLayout = lazy(() => import("../layouts/FullLayout/CoordinatorLayout.js"));

/***** Admin Pages******/
const Dashboard1 = lazy(() => import("../views/User/Dashboard1.js"));
const AvailableDA = lazy(() => import("../views/AvailableDA.js"));
const Domains = lazy(() => import("../views/User/Domains.js"));
const QuizPage = lazy(() => import("../views/User/QuizPage.js"));
const Instructions = lazy(() => import("../views/User/Instructions.js"));
const ResultPage = lazy(() => import("../views/User/ResultPage.js"));
const Home = lazy(() => import("../views/Home.js"));
const HomeTraining = lazy(() => import("../views/HomeTraining.js"));
const HomeCertification = lazy(() => import("../views/HomeCertification.js"));
const HomeDomains = lazy(() => import("../views/HomeDomains.js"));
const HomeComing = lazy(() => import("../views/HomeComing.js"));
const ContactUs = lazy(() => import("../ContactUs.js"));
const About = lazy(() => import("../views/About.js"));
const NotFound = lazy(() => import("../NotFound.js"));
const QRvalidation = lazy(() => import("../views/User/QRvalidation.js"));
const QuestionAllocation = lazy(() =>import("../views/Admin/QuestionAllocation.js"));
const QuizLink = lazy(() => import("../views/Admin/QuizLink.js"));
const QuizAvailable = lazy(() => import("../views/Admin/AvailableQuiz.js"));
const QuizManager = lazy(() => import("../views/Admin/QuizManager.js"));

const Home1 = lazy(() => import("../views/Home1.js"));
const Home2 = lazy(() => import("../views/Home2.js"));
const HomeBody2 = lazy(() => import("../layouts/FullLayout/Body/HomeBody2.js"));
const Home3 = lazy(() => import("../views/Home3.js"));
const GeneratingHighSkilled = lazy(() => import("../GeneratingHighSkilled.js"));
// const Login = lazy(() => import("../views/Login.js"));
// const Register = lazy(() => import("../views/Register.js"));
// const Profile = lazy(() => import("../views/User/Profile.js"));
const Ivp = lazy(() => import("../IVP/ivp.js"));
const Cisodashboard = lazy(() => import("../views/CISO/cisodashboard.js"));
const CisoCalendar = lazy(() => import("../NewHomePage/CisoCalendar.js"));
const NotLoggedIn = lazy(() => import("../views/NotLoggedIn.js"));

/***** Instructor Routes******/
const Dashboard = lazy(() => import("../views/Instrutor/Dashboard.js"));
const ViewQuestions = lazy(() => import("../views/Instrutor/ViewQuestions.js"));
// const ViewQuestions = lazy(() => import("../views/Instrutor/ViewMyQuestions.js"));
const LinkCertificate = lazy(() => import("../views/Instrutor/LinkCertificate.js"));
const QuestionUpload = lazy(() => import("../views/Instrutor/QuestionUpload.js"));
const InstructorRemarks = lazy(() => import("../views/Instrutor/InsRemarks.js"));
const InstructorDashboard = lazy(() => import("../views/Instrutor/InstructorDashboard.js"));
const AwarenessDashboard = lazy(() => import("../views/Instrutor/AwarenessDashboard.js"));
const AwarenessUploadQuestions = lazy(() => import("../views/Instrutor/AwarenessUploadQuestions.js"));
const AwarenessQuestionUpload = lazy(() => import("../views/Instrutor/AwarenessQuestionUpload.js"));
const ViewAwarenessQuestions = lazy(() => import("../views/Instrutor/ViewAwarenessQuestions.js"));
const ScoreRange = lazy(() => import("../views/Instrutor/ScoreRange.js"));
const UploadAwarenessCertificate = lazy(() =>import("../views/Instrutor/UploadAwarenessCertificate.js"));

// const AwarenessDashboard = lazy(() => import("../views/Instrutor/AwarenessDashboard.js"));

/***** Admin Routes******/
const AdminDashboard = lazy(() => import("../views/Admin/admindashboard.js"));
const UserManagement = lazy(() => import("../views/Admin/UserManagement.js"));
const ManageQuestions = lazy(() => import("../views/Admin/UpdateParameters.js"));
const TrainingCalender = lazy(() => import("../views/Admin/TrainingCalender.js"));
const DailyReport = lazy(() => import("../views/Admin/DailyReport.js"));
const ExamReport = lazy(() => import("../views/Admin/ExamReport.js"));
const EventCreation = lazy(() => import("../views/Admin/EventCreation.js"));

/***** Reviewer Routes******/
const ReviwerDashboard = lazy(() => import("../views/Reviewer/reviewerdashboard.js"));
const QuestionApproval = lazy(() => import("../views/Reviewer/QuestionApproval.js"));
const AllRemarks = lazy(() => import("../views/Reviewer/AllRemarks.js"));

/***** Coordinator Routes******/
const CoordinatorDashboard = lazy(() => import("../views/Coordinator/CoordinatorDashboard.js"));
const TrainingCalendar = lazy(() => import("../views/Coordinator/TrainingCalendar.js"));
// const QuestionApproval = lazy(() => import("../views/Reviewer/QuestionApproval.js"));
// const AllRemarks = lazy(() => import("../views/Reviewer/AllRemarks.js"));

/*****Routes******/

// const LoggedIn = Cookies.get("session_key");
const isAuthenticated = Cookies.get("access_token");

let decodedToken = null;
let userRole = null;

if (isAuthenticated) {
  decodedToken = jwtDecode(isAuthenticated);
  userRole = decodedToken ? decodedToken.role : null;
}
console.log("roleeeee in app", userRole);

const protectedRoutes =
  userRole === "user"
    ? [
        // {
        //   element: <CisoLayout />,
        //   children: [{ path: "/ciso", element: <Cisodashboard /> }],
        // },
        {
          element: <FullLayout />,
          children: [
            { path: "/dashboard", element: <Dashboard1 /> },
            { path: "/form", element: <Domains /> },
            { path: "/quiz", element: <QuizPage /> },
            { path: "/quiz/:quiz_code", element: <Instructions /> },
            { path: "/results", element: <ResultPage /> },
            // { path: "/profile", element: <Profile /> },
            // { path: "/instructions", element: <Instructions /> },
          ],
        },
      ]
    : userRole === "instructor"
    ? [
        {
          element: <InstructorDashboardLayout />,
          children: [
            { path: "/instructor_dashboard", element: <InstructorDashboard /> },
          ],
        },
        {
          element: <InstructorLayout />,
          children: [
            { path: "/dashboard1", element: <Dashboard /> },
            { path: "/uploadquestions", element: <QuestionUpload /> },
            { path: "/questions", element: <ViewAwarenessQuestions /> },
            { path: "/question_remarks", element: <InstructorRemarks /> },
          ],
        },
        {
          element: <AwarenessLayout />,
          children: [
            { path: "/dashboard_a", element: <AwarenessDashboard /> },
            { path: "/uploadquestions_a", element: <AwarenessQuestionUpload />,},
            { path: "/awareness_questions", element: <ViewAwarenessQuestions />,},
            { path: "/question_remarks", element: <InstructorRemarks /> },
            { path: "/score_range_message", element: <ScoreRange /> },
            { path: "/upload_certificate", element: <UploadAwarenessCertificate /> },
            { path: "/link_certificate", element: <LinkCertificate /> },
          ],
        },
      ]
    : userRole === "admin"
    ? [
        {
          element: <AdminLayout />,
          children: [
            { path: "/admindashboard", element: <AdminDashboard /> },
            { path: "/usermanagement", element: <UserManagement /> },
            { path: "/managequestions", element: <ManageQuestions /> },
            { path: "/trainingcalender", element: <TrainingCalender /> },
            { path: "/quiz_link", element: <QuizManager /> },
            { path: "/userreports", element: <DailyReport /> },
            { path: "/examreport", element: <ExamReport /> },
            // { path: "/available_quiz", element: <QuizAvailable /> },
            // { path: "/event_creation", element: <EventCreation /> },
            { path: "/question_allocation", element: <QuestionAllocation /> },
            // { path: "/all_trainings", element: <AllTrainings /> },
            // { path: "/training_details", element: <TrainingDetails /> },
          ],
        },
      ]
    : userRole === "coordinator"
    ? [
        {
          element: <CoordinatorLayout />,
          children: [
            { path: "/coordinatordashboard", element: <CoordinatorDashboard />,},
            { path: "/trainingcalendar", element: <TrainingCalendar /> },
            // { path: "/question_approval", element: <QuestionApproval /> },
            // { path: "/remarks", element: <AllRemarks /> },
          ],
        },
      ]
    : userRole === "reviewer"
    ? [
        {
          element: <ReviewerLayout />,
          children: [
            { path: "/reviewerdashboard", element: <ReviwerDashboard /> },
            { path: "/question_approval", element: <QuestionApproval /> },
            { path: "/remarks", element: <AllRemarks /> },
          ],
        },
      ]
    : [];

const publicRoutes = [
  { path: "/", element: <Home /> },
  { path: "/ciso_calendar", element: <CisoCalendar /> },
  { path: "/ciso_training", element: <HomeTraining /> },
  { path: "/ciso_Certification", element: <HomeCertification /> },
  { path: "/training-certification", element: <GeneratingHighSkilled /> },
  { path: "/domains-13", element: <HomeDomains /> },
  { path: "/comingsoon", element: <HomeComing /> },
  { path: "/contact", element: <ContactUs /> },
  { path: "/dareport/:qrCode", element: <QRvalidation /> },
  { path: "/about", element: <About /> },
  { path: "/ics_training", element: <Home1 /> },
  { path: "/telecom_training", element: <Home2 /> },
  { path: "/ics_training_june", element: <Home3 /> },
  { path: "/training/:eventUnicode", element: <HomeBody2 /> },
  { path: "/available_DA", element: <AvailableDA /> },
  { path: "/ivp-login/:url", element: <Ivp /> },
  { path: "*", element: <Navigate to="/" replace /> },
  // { path: "*", element: <NotFound /> },
  // { path: "/notloggedin", element: <NotLoggedIn /> },
  // { path: "/quiz/:quiz_code", element: <Instructions /> },
  // { path: "/login", element: <Login /> },
  // { path: "/register", element: <Register /> },
  {
    element: <FullLayout />,
    children: [
      // { path: "/quiz", element: <QuizPage /> },
      { path: "/quiz/:quiz_code", element: <Instructions /> },
      // { path: "/results", element: <ResultPage /> },
    ],
  },
];

const ThemeRoutes = isAuthenticated
  ? [...publicRoutes, ...protectedRoutes]
  : publicRoutes;

export default ThemeRoutes;
